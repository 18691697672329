import React from 'react';
import ReactSelect from 'react-select';
import { useTheme } from 'styled-components';

const Select = (props) => {
  const whuTheme = useTheme();

  return (
    <ReactSelect
      {...props}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        spacing: {
          ...theme.spacing,
          menuGutter: 2,
        },
        colors: {
          ...theme.colors,
          primary: whuTheme.palette.primary,
        }
      })}
    />
  )
}

export default Select;
